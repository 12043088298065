cei.shared.tagmanager = (function () {

    /**
     * @description push object to GA3 data layer
     * @param {object} obj - object to be pushed to data layer
     * @example addToDataLayer({'property_name': 'property_value'})
     */
    function addToDataLayer(obj) {
        var dataLayer = window.dataLayer = window.dataLayer || [];
        dataLayer.push(obj);
    }

    /**
     * @description push object to GA4 data layer
     * @param {object} obj - object to be pushed to data layer
     * @param {boolean} bool - boolean for e-commerce events
     * @example addToGA4DataLayer({'property_name': 'property_value'}, bool)
     */
    function addToGA4DataLayer(obj, bool) {
        if (obj) {
            var datalayer = window.dataLayer = window.dataLayer || [];

            if (bool) {
                for (var i = 0, j = datalayer.length; i < j; i += 1) {
                    if ('ecommerce' in datalayer[i]) {
                        dataLayer.push({ ecommerce: null });
                        break;
                    }
                }
            }

            dataLayer.push(obj);
        }
    }

    /**
     * @description build GA4 Item Array using Cart Item View Model Data
     * @param {array} CartItemArray - array of cart item objects
     * @param {number} CartItemNumber - number of cart item objects
     * @param {string} listLocation - page where list can be found
     * @example GA4_buildItemArr([{'property_name': 'property_value'}], 3, 'Shopping Cart')
     */
    function GA4_buildItemArr(CartItemArray, CartItemNumber, listLocation) {
        let GA4Items = [];
        if (CartItemArray && CartItemArray.length) {
            let cartItemArrayLength = CartItemArray.length;
            let j = CartItemNumber ? cartItemArrayLength < CartItemNumber ? cartItemArrayLength : CartItemNumber : cartItemArrayLength;
            for (let i = 0; i < j; i += 1) {
                if (CartItemArray[i]) {

                    // Price
                    let regularPrice = CartItemArray[i].RegularPrice && CartItemArray[i].RegularPrice.Amount ? CartItemArray[i].RegularPrice.Amount : undefined;
                    let salePrice = CartItemArray[i].EpiSalePrice && CartItemArray[i].EpiSalePrice.Amount ? CartItemArray[i].EpiSalePrice.Amount : undefined;
                    let priceObj = GA4SetPriceObject(regularPrice, salePrice, false);

                    // GTM - GA4
                    GA4Items.push({
                        'item_id': CartItemArray[i].ItemNumber ? CartItemArray[i].ItemNumber : undefined,
                        'item_name': CartItemArray[i].DisplayName ? CartItemArray[i].DisplayName : undefined,
                        'item_brand': CartItemArray[i].Brands ? CartItemArray[i].Brands : undefined,
                        'item_category': undefined,
                        'item_category2': undefined,
                        'item_category3': undefined,
                        'item_category4': undefined,
                        'item_category5': undefined,
                        'category': CartItemArray[i].Category ? CartItemArray[i].Category : undefined,
                        'subcategory': CartItemArray[i].SubCategory ? CartItemArray[i].SubCategory : undefined,
                        'season': CartItemArray[i].Season ? CartItemArray[i].Season : undefined,
                        'item_variant': CartItemArray[i].ItemVariant ? CartItemArray[i].ItemVariant : undefined,
                        'affiliation': 'Online store',
                        'price': priceObj.price ? priceObj.price : undefined,
                        'price_type': CartItemArray[i].PriceType ? CartItemArray[i].PriceType : undefined,
                        'discount': CartItemArray[i].DiscountAmount && CartItemArray[i].DiscountAmount.Amount ? CartItemArray[i].DiscountAmount.Amount : undefined,
                        'currency': 'USD',
                        'quantity': CartItemArray[i].Quantity ? CartItemArray[i].Quantity : undefined,
                        'coupon': undefined,
                        'index': i + 1,
                        'item_list_name': CartItemArray[i].CartName ? CartItemArray[i].CartName : undefined,
                        'item_list_location': listLocation ? listLocation : undefined,
                        'item_list_id': undefined,
                        'inventory': CartItemArray[i].Inventory ? CartItemArray[i].Inventory : undefined,
                        'rating': typeof CartItemArray[i].Rating !== 'undefined' && CartItemArray[i].Rating !== null ? CartItemArray[i].Rating : undefined,
                        'drop_ship': typeof CartItemArray[i].DropShip !== 'undefined' && CartItemArray[i].DropShip !== null ? CartItemArray[i].DropShip : undefined,
                        'new': typeof CartItemArray[i].New !== 'undefined' && CartItemArray[i].New !== null ? CartItemArray[i].New : undefined,
                        'exclusive': typeof CartItemArray[i].Exclusive !== 'undefined' && CartItemArray[i].Exclusive !== null ? CartItemArray[i].Exclusive : undefined,
                        'personalized': typeof CartItemArray[i].Personalized !== 'undefined' && CartItemArray[i].Personalized !== null ? CartItemArray[i].Personalized : undefined
                    });
                }
            }
        }

        return GA4Items;
    }

    /**
     * @description build GA4 Item Array using Product View Model Data
     * @param {array} productArray - array of product objects
     * @param {string} listName - list name
     * @param {string} listLocation - list location
     * @example GA4BuildProductItemArray([{'property_name': 'property_value'}], 'Home Decor', 'category')
     */
    function GA4BuildProductArray(productArray, listName, listLocation) {
        var GA4Items = [], i;

        if (productArray && productArray.length) {
            for (i = 0; i < 6; i += 1) {
                if (productArray[i]) {

                    // Price
                    let regularPrice = productArray[i].Price ? productArray[i].Price : undefined;
                    let salePrice = productArray[i].SalePrice ? productArray[i].SalePrice : undefined;
                    let priceObj = GA4SetPriceObject(regularPrice, salePrice, true);
                   

                    // GTM - GA4
                    GA4Items.push({
                        'item_id': productArray[i].ItemNumber ? productArray[i].ItemNumber : undefined,
                        'item_name': productArray[i].H1Keyword ? productArray[i].H1Keyword : undefined,
                        'item_brand': productArray[i].Brands ? productArray[i].Brands : undefined,
                        'item_category': undefined,
                        'item_category2': undefined,
                        'item_category3': undefined,
                        'item_category4': undefined,
                        'item_category5': undefined,
                        'category': productArray[i].Category ? productArray[i].Category : undefined,
                        'subcategory': productArray[i].SubCategory ? productArray[i].SubCategory : undefined,
                        'season': productArray[i].Season ? productArray[i].Season : undefined,
                        'item_variant': productArray[i].ItemVariant ? productArray[i].ItemVariant : undefined,
                        'affiliation': 'online store',
                        'price': priceObj.price ? priceObj.price : undefined,
                        'price_type': productArray[i].PriceType ? productArray[i].PriceType : undefined,
                        'discount': priceObj.priceDifference ? cei.shared.utilities.round(priceObj.priceDifference, 2) : undefined,
                        'quantity': 1,
                        'item_list_name': listName ? listName : undefined,
                        'item_list_location': listLocation ? listLocation : undefined,
                        'item_list_id': undefined,
                        'index': i + 1,
                        'inventory': productArray[i].Inventory ? productArray[i].Inventory : undefined,
                        'rating': typeof productArray[i].Rating !== 'undefined' && productArray[i].Rating !== null ? productArray[i].Rating : undefined,
                        'drop_ship': typeof productArray[i].DropShip !== 'undefined' && productArray[i].DropShip !== null ? productArray[i].DropShip : undefined,
                        'new': typeof productArray[i].New !== 'undefined' && productArray[i].New !== null ? productArray[i].New : undefined,
                        'exclusive': typeof productArray[i].Exclusive !== 'undefined' && productArray[i].Exclusive !== null ? productArray[i].Exclusive : undefined,
                        'personalized': typeof productArray[i].Personalized !== 'undefined' && productArray[i].Personalized !== null ? productArray[i].Personalized : undefined
                    });
                }
            }
        }

        return GA4Items;
    }

    /**
     * @description Set price/price difference to be sent to GTM/GA4 Data Layer
     * @param {number} regularPrice - regular price
     * @param {number} salePrice - sale price
     * @param {boolean} hasPriceDifference - a price difference needs to be set
     * @example GA4SetPrice(24.99, 22.99, true)
     */
    function GA4SetPriceObject(regularPrice, salePrice, hasPriceDifference) {
        let priceObj = {};

        if (hasPriceDifference) priceObj.priceDifference = 0;
        
        if (regularPrice) {
            priceObj.price = regularPrice;

            if (salePrice && salePrice < regularPrice) {
                priceObj.price = salePrice;
                if (hasPriceDifference) priceObj.priceDifference = regularPrice - salePrice;
            }
        }

        return priceObj;
    }

    return {
        addToDataLayer: addToDataLayer,
        addToGA4DataLayer: addToGA4DataLayer,
        GA4_buildItemArr: GA4_buildItemArr,
        GA4BuildProductArray: GA4BuildProductArray,
        GA4SetPriceObject: GA4SetPriceObject
    };

})();